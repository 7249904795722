import { Block } from "./block";
/**
 *
 * This file is compiled, the build file can be found in the assets/build folder
 */
document.addEventListener("DOMContentLoaded", function () {
	const blocks = document.querySelectorAll(".block_SL002");
	if (!blocks.length) return;
	blocks.forEach((b) => new Block(b).init());
});
