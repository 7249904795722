import Swiper from "swiper";
import { Navigation, Pagination, Scrollbar, EffectFade } from "swiper/modules";

export class Block {
	constructor(node) {
		this.nodeElem = node;
		this.swiperElem = node.querySelector(".swiper-solutions");
		this.nextElem = node.querySelector(".swiper-nav .swiper-button-next");
		this.prevElem = node.querySelector(".swiper-nav .swiper-button-prev");
		this.paginationElem = node.querySelector(".swiper-pagination");
		this.scrollbarElem = node.querySelector(".swiper-scrollbar");
		this.swiper = null;
		this.y = this.nodeElem.getBoundingClientRect().top + window.scrollY + 60;
		this.headerLinks = document.querySelectorAll(".col-header-link");
	}
	init() {
		if (!this.swiperElem) return;
		this.swiper = new Swiper(this.swiperElem, {
			modules: [Navigation, Pagination, Scrollbar, EffectFade],
			navigation: {
				enabled: true,
				nextEl: this.nextElem,
				prevEl: this.prevElem,
			},
			pagination: {
				el: this.paginationElem,
				type: "fraction",				
				formatFractionCurrent: function (number) {
					return ('0' + number).slice(-2);
				},
				formatFractionTotal: function (number) {
					return ('0' + number).slice(-2);
				},
				renderFraction: function (currentClass, totalClass) {
					return '<span class="font-semibold text-[14px] ' + currentClass + '"></span>' +
						   '/' +
						   '<span class="font-semibold text-[14px] ' + totalClass + '"></span>';
				}
			},
			scrollbar: {
				el: this.scrollbarElem,
			},
			effect: "fade",
			on: {
				slideChange: (sld) => {
					document.body.setAttribute("data-sld", sld.realIndex);
					if (this.nodeElem.classList.contains("revedia-slider")) {
						if (sld.realIndex == 0) {
							// update anchor
							window.history.replaceState(null, null, "#revenue-operations");
						}
						if (sld.realIndex == 1) {
							// update anchor
							window.history.replaceState(null, null, "#content-licensing");
						}
					}
				},
			},
		});

		if (window.location.hash) {
			// if it exists, scroll to the second slide
			window.scrollTo({ top: this.y, behavior: "smooth" });
			if (window.location.hash == "#content-licensing") {
				this.swiper.slideTo(1, 0);
			}
		}
		this.headerLinks.forEach((link) => {
			link.addEventListener("click", (e) => {
				// if target has anchor, scroll to this.nodeElem and select the correct slide
				if (e.target.hash) {
					e.preventDefault();
					window.scrollTo({ top: this.y, behavior: "smooth" });

					if (e.target.hash == "#revenue-operations") {
						this.swiper.slideTo(0, 0);
					}
					if (e.target.hash == "#content-licensing") {
						this.swiper.slideTo(1, 0);
					}
				}
			});
		});
	}
}
